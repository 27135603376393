const menu_data = [
  {
    id: 1,
    title: 'Ana Sayfa',
    link: '/',
  },
  {
    id: 2,
    hasDropdown: true,
    title: 'Çalışma Alanlarımız',
    link: '/calisma-alanlarimiz',
    submenus: [
      { title: 'İş ve Sosyal Güvenlİk Hukuku', link: '/is-ve-sosyal-guvenlik-hukuku' },
      { title: 'Dava Takibi & Anlaşmazlıkların Çözümü', link: '/dava-takibi' },
      { title: 'Aİle Hukuku', link: '/aile-hukuku' },
      { title: 'Gayrİmenkul Hukuku', link: '/gayrimenkul-hukuku' },
      { title: 'Sözleşmeler Hukuku', link: '/sozlesmeler-hukuku' },
      { title: 'Tİcaret ve Şİrketler Hukuku', link: '/ticaret-ve-sirketler-hukuku' },
      { title: 'İcra & İflas Hukuku', link: '/icra-iflas-hukuku' },
      { title: 'Ceza Hukuku', link: '/ceza-hukuku' },
      { title: 'Göç ve Yabancılar Hukuku', link: '/goc-ve-yabancilar-hukuku' },
      { title: 'Tüketİcİ Hukuku', link: '/tuketici-hukuku' },
      { title: 'Mİras Hukuku', link: '/miras-hukuku' },
      { title: 'İdare Hukuku', link: '/idare-hukuku' },
    ]
  },
  {
    id: 3,
    title: 'İletişim',
    link: '/iletisim',
  },
]

export default menu_data;

