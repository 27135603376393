// src/analytics.js
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-Z0M4W6ZRVK'; // Replace with your Measurement ID
export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};
