import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";

import TicaretveSirketlerHukuku from "./TicaretVeSirketlerHukuku";

const TicaretveSirketlerHukukuPage = () => {
  return (
    <>
      <Menu header_style_1={true} />
      <div className="service-placeholder mt-130">
      </div>
      <TicaretveSirketlerHukuku />
      <Footer />
    </>
  );
};

export default TicaretveSirketlerHukukuPage;
