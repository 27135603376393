import React from "react";

const CookieAbout = () => {
    return (
        <>
            <div id="hakkimizda" className="about-details-page pt-140 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-details-info pr-65">
                                <div className="tp-section-wrapper">
                                    <h3 style={{ textAlign: "center" }}>
                                        UYSAL & KARACA HUKUK BÜROSU ÇEREZ POLİTİKASI ve AYDINLATMA METNİ
                                    </h3>
                                </div>
                                <div className="about-tb-content">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-mission"
                                            role="tabpanel"
                                            aria-labelledby="nav-mission"
                                        >
                                            <p className="text-justify">
                                                Uysal & Karaca Hukuk Bürosu olarak siz değerli müvekkillerimizin kişisel verilerinin
                                                gizliliğine ve güvenliğine önem veriyoruz. Bu kapsamda, veri sorumlusu sıfatıyla
                                                Uysal & Karaca Hukuk Bürosu olarak kişisel verilerinizi 6698 sayılı Kişisel Verilerin
                                                Korunması Kanunu’na (“KVKK” veya “Kanun”) uygun olarak işlenmesine ve veri
                                                güvenliğinin sağlanmasına büyük önem vermekteyiz. İşbu aydınlatma Uysal & Karaca
                                                Hukuk Bürosu’nun Kanun’a uygun şekilde gerçekleştirdiği kişisel veri işleme
                                                faaliyetlerine ve çerezlere ilişkin genel açıklamalara ilişkin Uysal & Karaca Hukuk
                                                Bürosu’na ait https://uysalkaraca.com/ web sitesi (“Web Sitesi”) ziyaretçilerini
                                                bilgilendirmek amacıyla hazırlanmıştır.
                                            </p>
                                            <h3>1. ÇEREZ NEDİR?</h3>
                                            <p>Çerez, bir internet sitesini veya mobil uygulamayı ziyaret ettiğinizde tarayıcınıza veya
                                                cihazınıza depolanan küçük boyutlu dosyalardır. Çerezler kullanıcının ve cihazının
                                                tanınmasına, kullanıcının tercihleri ve geçmiş işlemleriyle ilgili bazı bilgilerin
                                                depolanmasına imkân tanır.</p>
                                            <p>Web Sitesi kapsamında çerezler kullanılmaktadır. Çerezler kesinlikle bir virüs değildir.
                                                Web Sitesi’nin kullanımını daha iyi anlayabilmeniz için kullanılan özel bir tanımlayıcı
                                                içeren çerezleri sizin tarayıcılarınıza gönderirler; böylece ziyaretçilerin Web Sitesi’nin
                                                hangi alanlarını kullanmayı tercih ettiklerini (ör: bu alanlara giren ziyaretçi sayısı
                                                esasına göre) anlayabiliriz. Temel olarak çerezlerin, (i) kullanıcının yeniden ziyaret
                                                ettiğini siteye bildirmek, (ii) tarayıcı ayarlarının, giriş bilgilerinin ve sistem bilgisinin
                                                hatırlamak veya (iii) kullanıcıya hizmet odaklı ve optimize edilmiş bir Web Sitesi
                                                sunulmasını sağlamak gibi fonksiyonları bulunmaktadır. Çerezler vasıtasıyla veriler,
                                                otomatik yöntemlerle (insan müdahalesi olmaksızın) toplanmaktadır. Çerezler,
                                                kullanım ömürlerine göre oturum çerezleri ve kalıcı çerezler olarak ikiye ayrılır.
                                                Oturum çerezleri, ilgili oturum sonunda otomatik olarak silinir. Kalıcı çerezler ise
                                                kullanım ömürleri boyunca cihazınızda depolanır ve kullanım ömrü sonunda silinir. Bu
                                                tür çerezler, internet sitesini tekrar ziyaret ettiğinizde sizi ve tercihlerinizi hatırlamaya
                                                yarar. Çerezler, ziyaret ettiğiniz Web Sitesi tarafından (birinci taraf çerezler) ya da
                                                üçüncü taraflar tarafından (üçüncü taraf çerezler) oluşturulabilir.</p>
                                            <h3>2. ÇEREZ KULLANIM AMAÇLARIMIZ</h3>
                                            <p>
                                                İçtem Legal olarak, faaliyetlerin etkili, verimli ve hukuka uygun şekilde yürütülmesi
                                                adına aşağıdaki amaçlarla Web Sitesi’nde çerezler kullanmaktayız.
                                            </p>
                                            <ul>
                                                <li>İş Süreçlerinin İyileştirilmesine Yönelik Önerilerin Alınması ve Değerlendirilmesi</li>
                                                <li>Ziyaretçi Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</li>
                                                <li>Veri Sorumlusu Operasyonlarının Güvenliğinin Temini</li>
                                                <li>Analiz Çalışmalarının Yürütülmesi</li>
                                                <li>Web Sitesi’nin Çalışması İçin Gerekli Temel Fonksiyonların Gerçekleştirilmesi</li>
                                                <li>Web Sitesi’nin Analiz Edilmesi ve Performansının Arttırılması</li>
                                                <li>Web Sitesi’nin Kullanımı Hakkında Veri Depolanması ve Depolanan Verilerin
                                                    İşlenmesi Suretiyle Web Sitesi’nin İşlevselliğinin Arttırılması ve Daha İyi Bir Kullanıcı
                                                    Deneyimi Sunulması</li>
                                                <li>Web Sitesi’nin Güvenliğinin Sağlanması, Hileli ya da İzinsiz Kullanımların Tespit
                                                    Edilmesi</li>
                                            </ul>
                                            <br />

                                            <h3>3. ÇEREZLER VASITASIYLA İŞLENEN KİŞİSEL VERİLERİNİZ</h3>
                                            <p>
                                                Çerezler aracılığıyla, (i) kullandığınız tarayıcı ve işletim sistemi, (ii) IP adresi, (iii)
                                                etkileşim durumu (örneğin, Web Sitesi’ne erişip erişemediğiniz veya bir hata uyarısı
                                                alıp almadığınız), (iv) Plaform’daki özelliklerin kullanımı, (v) sayfaları kaydırma
                                                hareketleri, (vi) eriştiğiniz sekmelere ilişkin bilgiler dahil ziyaretçi işlem kayıtlarına
                                                ilişkin veriler toplanmakta ve işlenmektedir.
                                            </p>

                                            <h3>4. WEB SİTESİ’NDE KULLANILAN ÇEREZLER</h3>
                                            <h4>4.1. Zorunlu Çerezler</h4>
                                            <p>
                                                Web Sitesi’nin kullanımı ve işlerliği için gerekli olan çerezlerdir. Zorunlu çerezler
                                                devre dışı bırakıldığı takdirde sitenin bir kısmına veya tümüne erişim mümkün
                                                olmayabilir. Bu sebeple zorunlu çerezlerin kullanılması gereklidir.
                                            </p>
                                            <h5>
                                                a. Birinci Taraf Çerezler
                                            </h5>
                                            <p>
                                                Web Sitesi’nde kullanılan, doğrudan İçtem Legal tarafından oluşturulan birinci taraf
                                                çerezlerdir.
                                            </p>
                                            <h5>b. Üçüncü Taraf Çerezleri</h5>
                                            <p>Web Sitesi’nde kullanılan üçüncü taraflara ait çerezlerdir.</p>
                                            <h4>4.2. Analiz / Performans Çerezleri</h4>
                                            <p>
                                                Analiz çerezleri kullanıcıların Web Sitesi’ni nasıl kullandığı hakkında bilgi toplar. Bu
                                                tür çerezler kullanıcı deneyimlerini iyileştirmek/geliştirmek amacıyla kullanılan
                                                çerezlerdir. Bu çerezler, kullanıcılar tarafından görüntülenen ekranlar/bağlantılar,
                                                tıklanma sayıları, sayfaları kaydırma hareketleri ve bu sayfalara giriş ve çıkış
                                                zamanları gibi bilgileri toplar.
                                            </p>
                                            <p>
                                                Web Sitesi’nde kullanılan üçüncü taraf analiz çerezleri ve kullanım amaçları
                                                aşağıdaki şekildedir:
                                            </p>

                                            <h3>
                                                Çerez Adı : Google Analytics
                                            </h3>
                                            <h3>
                                                Kullanım Amacı
                                            </h3>
                                            <p>
                                                Google Analytics, çerezleri ziyaretçilerin Web Sitesi’ni nasıl kullandıklarını analiz
                                                etmek amacıyla kullanır. Bu Web Sitesi’ni kullanımınızla ilgili bilgiler (IP adresiniz
                                                dahil) Google&#39;a aktarılarak Google tarafından ABD&#39;deki sunucularda saklanmaktadır.
                                                Google bu bilgileri Web Sitesini kullanımınızı değerlendirmek, İçtem Legal için Web
                                                Sitesi faaliyetini derlemek ve Web Sitesi faaliyeti ve internet kullanımıyla ilgili başka
                                                hizmetler sağlamak amacıyla kullanacaktır, fakat IP adresinizi Google tarafından
                                                depolanan diğer verilerle eşleştirmeyecektir.
                                            </p>
                                            <h3>
                                                5. KİŞİSEL VERİLERİNİZİN KAYNAĞI
                                            </h3>
                                            <p>
                                                İşbu Aydınlatma Metni kapsamında yer alan kişisel verileriniz Web Sitesi’nde
                                                kullanılan çerezler vasıtasıyla elde edilmektedir.
                                            </p>

                                            <h3>
                                                6. KİŞİSEL VERİLERİNİZİN İŞLENMESİNİN HUKUKİ SEBEPLERİ
                                            </h3>

                                            <p>
                                                Zorunlu Çerezler; Kanun’un 5. maddesinin 2. fıkrası kapsamında yer alan;
                                            </p>

                                            <ul>
                                                <li>Veri sorumlusunun hukuki yükümlülüğünün yerine getirebilmesi için zorunlu
                                                    olması</li>

                                                <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
                                                    kaydıyla sözleşmenin taraflarına ait kişisel verilerin işlenmesi</li>
                                                <li>Temel hak ve özgürlüklerinize zarar vermemek kaydıyla İçtem Legal’in
                                                    meşru menfaatleri için kişisel verilerinizin işlenmesinin zorunlu olması</li>
                                            </ul>

                                            <p>
                                                hukuki sebeplerine dayalı olarak toplanmakta ve işlemektedir.
                                            </p>
                                            <p>
                                                <span style={{ fontWeight: 600 }}> Analiz / Performans Çerezleri; </span>
                                                açık rızanızın varlığı halinde işlenmektedir.
                                            </p>

                                            <h3>
                                                7. ÇEREZ TERCİHLERİ ve DEĞİŞİKLİK
                                            </h3>
                                            <p>
                                                Çerezlerinize ilişkin tercihlerinize her zaman tarayıcı ayarlarınızdan erişebilir ve
                                                bunları değiştirebilirsiniz. Buna karşın bu durumda Web Sitesi’nin bir kısım veya tüm
                                                fonksiyonlarından yararlanılması mümkün olmayabilir. Web Sitesi’nin işlerliği için
                                                zorunlu olan ve işbu Aydınlatma Metni’nin Zorunlu Çerezler başlığı altında yer alan
                                                çerezlerin engellenmesi ise mümkün değildir. Çerezleri nasıl devre dışı bırakacağınız
                                                hakkında, aşağıda tarayıcınıza özgü linke tıklayarak bilgi alabilirsiniz:
                                            </p>

                                            <ul>
                                                <li>Google Chrome</li>
                                                <li>Microsoft Edge</li>
                                                <li>Mozilla Firefox</li>
                                                <li>Safari</li>
                                                <li>Opera Çerez</li>
                                            </ul>
                                            <p>
                                                tercihlerinizi değiştirmek için ayrıca Çerez Ayarları’nı ziyaret edebilirsiniz.
                                            </p>
                                            <h3>
                                                8. KİŞİSEL VERİLERİNİZİN AKTARILMASI
                                            </h3>
                                            <p>
                                                Uysal & Karaca Hukuk Bürosu tarafından elde edilen kişisel verileriniz, Kanun’da
                                                öngörülen temel ilkelere ve Kanun’un 8. maddesinde sayılan kişisel veri işleme
                                                şartlarına uygun olarak açık rızanız bulunmaksızın yurt içinde bulunan ve/veya
                                                Kanun’un 9. maddesine uygun şekilde gerektiğinde açık rızanıza dayalı olarak yurt
                                                dışında bulunan ve listesi aşağıda yer alan iş ortaklarımıza, tedarikçilerimize ve
                                                kanunen yetkili kamu kuruluşları ile özel kişilere aktarılabilecektir.
                                            </p>
                                            <ul>
                                                <li>Uysal & Karaca Hukuk Bürosu’nun hizmet aldığı ve/veya iş birliği yaptığı yazılım,
                                                    telekomünikasyon, sigorta, arşiv, sunucu (server) barındırma, veri tabanı, veri
                                                    merkezi, bulut (cloud) platformlarına, bunların tedarikçilerine veya bayilerine</li>
                                                <li>Uysal & Karaca Hukuk Bürosu kullanıcıların güvenliğini sağlamak, hileli ya da izinsiz
                                                    kullanımları tespit etmek, operasyonel değerlendirme araştırması yapmak
                                                    amaçlarıyla hizmet aldığı üçüncü kişilere</li>
                                                <li>Uysal & Karaca Hukuk Bürosu’nun yurt içinde bulunan operasyonel danışmanlarına
                                                    ve bağımsız denetçiler de dahil olmak üzere bunların denetçilerine</li>
                                                <li>Bakanlıklar, ticaret sicil müdürlükleri, vergi daireleri, belediyeler, noterlikler,
                                                    mahkemeler, savcılıklar, emniyet teşkilatı ile Bankacılık Düzenleme ve Denetleme
                                                    Kurumu ile bankalar da dahil fakat bunlarla sınırlı olmamak üzere kanunen yetkili her
                                                    türlü kamu kuruluşlarına ve yetkili özel kişilere</li>
                                            </ul>
                                            <br/>
                                            <h3>
                                                9. KİŞİSEL VERİLERİNİZE İLİŞKİN OLARAK KANUN’UN 11. MADDESİ
                                                KAPSAMINDA SAHİP OLDUĞUNUZ HAKLAR VE KULLANMA YÖNTEMİ
                                            </h3>
                                            <p>Hukuk büromuz tarafından işlenen kişisel verileriniz bakımından hukuk büromuza
                                            başvurarak, kişisel verilerinizin</p>
                                            <ul>
                                                <li>işlenip işlenmediğini öğrenme,</li>
                                                <li>işlenmiş ise bilgi
                                                talep etme, </li>
                                                <li>işlenme amacını ve amacına uygun kullanılıp kullanılmadığını
                                                öğrenme,</li>
                                                <li>yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,</li>
                                                <li>eksik / yanlış
                                                işlenmişse düzeltilmesini isteme,</li>
                                                <li>KVKK m. 7’de öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,</li>
                                                <li>verilerinizin aktarıldığı 3. kişilere (v) ile (vi)
                                                bentlerindeki işlemlerin bildirilmesini isteme,</li>
                                                <li>münhasıran otomatik sistemler ile
                                                analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme</li>
                                                <li>kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
                                                giderilmesini talep etme,</li>
                                            </ul>
                                            <p>
                                                haklarınıza sahipsiniz
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookieAbout;
