import { useState, useEffect } from 'react';
import './cookieBannerCss.css';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const isCookieAccepted = localStorage.getItem('cookieAccepted');
        if (!isCookieAccepted) {
            setIsVisible(true);
        }
    }, []);

    const acceptCookie = () => {
        localStorage.setItem('cookieAccepted', true);
        setIsVisible(false);
    };

    const rejectCookie = () => {
        localStorage.setItem('cookieAccepted', false);
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="cookieBanner">
        <div>
            <p>
                İnternet sitemizde çerezler yoluyla kişisel veri işlenmekte olup; gerekli olan çerezler, bilgi toplumu hizmetlerinin sunulması amacıyla kullanılmaktadır. Diğer birinci ve üçüncü taraf çerezler ise açık rıza vermeniz halinde, sizlere yönelik reklam/pazarlama faaliyetlerinin yapılması, sitemizin daha işlevsel kılınması ve kişiselleştirmesi amaçlarıyla sınırlı olarak kullanılacaktır. Çerezlerle ilgili daha detaylı bilgiye <Link className='link' to="/cerezler">Çerez Aydınlatma Metni</Link>’nden ulaşabilirsiniz.
            </p>
        </div>
        <div>
            <button className='rejectBtn' onClick={rejectCookie}>Reddet</button>
            <button className='acceptBtn' onClick={acceptCookie}>Kabul et</button>
        </div>
    </div>
    
    );
}

export default CookieBanner;
