import { Link } from "react-router-dom";

const GayrimenkulHukuku = () => {
  return (
    <>
      <div className="tp-about-section black-bg p-relative">
        <div
          className="tp-ab-bg d-none d-lg-block"
          style={{
            backgroundImage: `url("assets/img/service/service-gayrimenkul-hukuku.jpg")`,
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-6">
              <div className="tp-ab-text pt-30 pl-50 p-relative">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-30">
                    Dava & Danışmanlık
                  </span>
                  <h2 className="tp-section-title text-white">
                    Gayrimenkul Hukuku
                  </h2>
                  <p className="mb-30 text-justify">
                    Uysal & Karaca Hukuk Bürosu kira sözleşmesinden kaynaklanan uyuşmazlıklar, kentsel dönüşüm davaları, kat mülkiyeti hukuku davaları, tapu tescil davaları, ortaklığın giderilmesi (izale-i şuyu) davaları, önalım hakkı (şufa) davaları, miras kalan taşınmazlara ilişkin davalar başta olmak üzere taşınmazlar ile alakalı tüm hukuki davalarda hizmet sağlamaktadır.
                  </p>

                  <p className="text-justify">
                    Ayrıca, bu alandaki hukuki danışmanlık hizmetleri kapsamında kira sözleşmeleri, kat karşılığı inşaat sözleşmeleri, taşınmaz satış vaadi sözleşmelerinin hazırlanması ve müzakeresi, yönetim planlarının hazırlanması gibi konularda müvekkillerine destek vermektedir.
                  </p>

                  <div className="mt-50 mb-130">
                    <Link to="/iletisim" className="tp-btn-white">
                      İletişime Geçin
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GayrimenkulHukuku;
