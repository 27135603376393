
import { Link } from "react-router-dom";

// single service
export function ServiceItem({ icon, title, desc, url }) {
  const handleCardClick = () => {
    window.location.href = url;
  };
  return (
    <div className="col-xl-4 col-md-6">
      <div className="tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30" onClick={handleCardClick}>
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          <Link to={url}>{title}</Link>
        </h3>
        <p className="tp-service__text">{desc}</p>
      </div>
    </div>
  );
}

const Services = () => {

  return (
      <div className="tp-service-ara grey-bg-4 pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-25">ÇALIŞMA ALANLARIMIZ</span>
                {/* <h2 className="tp-section-title">Explore Our Services</h2> */}
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="pe-7s-culture"
              title="İş ve Sosyal Güvenlik Hukuku"
              desc="Uysal&Karaca Hukuk Bürosu, hem yurtiçinde hem "
              url="/is-ve-sosyal-guvenlik-hukuku"
            />
            <ServiceItem
              icon="pe-7s-global"
              title="Dava Takibi"
              desc="Uysal&Karaca Hukuk Bürosu, hukuki dava ve uyuş"
              url="/dava-takibi"
            />
            <ServiceItem
              icon="pe-7s-credit"
              title="İcra ve İflas Hukuku"
              desc="Uysal & Karaca Hukuk Bürosu, müvekkillerine ic"
              url="/icra-iflas-hukuku"
            />
            <ServiceItem
              icon="pe-7s-home"
              title="Aile Hukuku"
              desc="Uysal & Karaca Hukuk Bürosu başta boşanma, mal"
              url="/aile-hukuku"
            />
            <ServiceItem
              icon="pe-7s-cash"
              title="Gayrimenkul Hukuku"
              desc="Uysal & Karaca Hukuk Bürosu kira sözleşmesinde"
              url="/gayrimenkul-hukuku"
            />
            <ServiceItem
              icon="pe-7s-safe"
              title="Sözleşmeler Hukuku"
              desc="Uysal&Karaca Hukuk Bürosu, sözleşmeler alanınd"
              url="/sozlesmeler-hukuku"
            />
            <ServiceItem
              icon="pe-7s-cart"
              title="Ticaret ve Şirketler Hukuku"
              desc="Şirket kuruluşları, şirket ana sözleşmenin hazırlanması"
              url="/ticaret-ve-sirketler-hukuku"
            />
            <ServiceItem
              icon="pe-7s-hammer"
              title="Ceza Hukuku"
              desc="Uysal & Karaca Hukuk Bürosu, gerek bireylerin "
              url="/ceza-hukuku"
            />
            <ServiceItem
              icon="pe-7s-helm"
              title="Göç ve Yabancılar Hukuku"
              desc="Uysal&Karaca Hukuk Bürosu yabancı ülke vatanda"
              url="/goc-ve-yabancilar-hukuku"
            />
            <ServiceItem
              icon="pe-7s-cart"
              title="Tüketici Hukuku"
              desc="Uysal&Karaca Hukuk Bürosu, sözleşmeler alanınd"
              url="/tuketici-hukuku"
            />
            <ServiceItem
              icon="pe-7s-home"
              title="Miras Hukuku"
              desc="Uysal&amp;Karaca Hukuk Bürosu ortaklığın giderilmesi"
              url="/miras-hukuku"
            />
            <ServiceItem
              icon="pe-7s-hammer"
              title="İdare Hukuku"
              desc="Uysal&amp;Karaca Hukuk Bürosu idareye başvuruların"
              url="/idare-hukuku"
            />
          </div>
        </div>
      </div> 
  );
};

export default Services;
