import React from "react";
import { Link } from "react-router-dom";
import team_data from "../../../data/teamData.json";

const AboutCompany = () => {
  return (
    <>
      <div id="hakkimizda" className="about-details-page pt-140">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    HAKKIMIZDA
                  </span>
                  {/* <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2> */}
                </div>
                <div className="about-tb-content">
                  {/* <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button>
                    </div>
                  </nav> */}
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p className="text-justify">
                        Kurucumuz Av. İlayda KARACA UYSAL, Bahçeşehir Üniversitesi Hukuk
                        Fakültesi&#39;nden mezun olduktan sonra İstanbul&#39;daki önde gelen hukuk bürolarında
                        edindiği deneyimle profesyonel kariyerine yön vermiştir. Bu süreçte iş hukuku, ticaret
                        ve şirketler hukuku, aile hukuku, kira hukuku, göç ve yabancılar hukuku, ticari dava
                        ve arabuluculuk gibi alanlarda derinlemesine uzmanlık kazanmıştır.
                      </p>

                      <p className="text-justify">
                        2024 yılı itibariyle kendi hukuk bürosunu kurarak Uysal&amp;Karaca Hukuk Bürosu çatısı
                        altında faaliyetlerine devam eden Av. İlayda KARACA UYSAL, bireysel ve kurumsal
                        müvekkillerine etkin, hızlı ve yenilikçi hukuki çözümler sunma misyonunu
                        benimsemektedir.
                      </p>

                      <p className="text-justify">
                        Kendisi çeşitli sektörel sivil toplum kuruluşlarında ve kadın hakları platformlarında
                        aktif görev alarak hukuki destek sağlamaktadır. İleri düzeyde İngilizce bilen Av. İlayda
                        Karaca Uysal, yabancı müvekkillerinin Türkiye&#39;deki hukuki ihtiyaçlarına yönelik göç
                        ve yabancılar hukuku alanında özel hizmetler sunmaktadır.
                      </p>

                      <p className="text-justify">
                        Uysal &amp; Karaca Hukuk Bürosu müvekkillerin ihtiyaçlarına duyarlı yaklaşımıyla,
                        hukukun her alanında güvenilir bir çözüm ortağı olmayı hedeflemektedir.
                      </p>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Phasellus volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Libero nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                        Neque volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Nibh nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                {/* <div className="about-page-img">
                  <img src="assets/img/about/about-page.png" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div> */}
                {/*<div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">10</h3>
                  <h4 className="box-subtitle">
                    Yıllık <br />
                    Deneyim
                  </h4>
                </div>*/}
              </div>
              {/* <div className="row team-row">
                {team_data.slice(0, 4).map((team, i) => (
                  <div key={i} className="col-xl-12 col-lg-6">
                    <div className="tp-team pb-30 pt-25">
                      <div className="tp-team__thumb">
                        <img src={team.img} alt="" />
                      </div>
                      <div className="tp-team__info pt-25">
                        <h3 className="tp-team-name">
                          <Link to="/team-details">{team.name}</Link>
                        </h3>
                        <p>{team.title}</p>
                      </div>
                      <div className="tp-team__social">
                        {team.social_links.map((l, i) => (
                          <a key={i} href={`${l.link}`} target={l.target}>
                            <i className={l.icon}></i>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="row team-row animation">
                <div className="col-xl-12 col-lg-6">
                  <div className="animation-container">
                    <p className="text-justify info-text">
                      Çalışma alanlarımız ile ilgili daha fazla bilgi almak için:
                    </p>
                    <button className="btn btn-primary info-button" onClick={() => window.location.href = '/calisma-alanlarimiz'}>Çalışma Alanlarımız</button>
                  </div>
                </div>
              </div>




              {/* <div className="row team-row">
                {team_data.slice(0, 4).map((team, i) => (
                  <div key={i} className="col-xl-12 col-lg-6">
                    <div className="tp-team pb-30 pt-25 text-center">
                      <div className="tp-team__thumb">
                        <img src={team.img} alt="" className="ilayda-img" />
                      </div>
                      <div className="tp-team__info pt-25">
                        <h3 className="tp-team-name">
                          <Link to="/team-details">{team.name}</Link>
                        </h3>
                        <p>{team.title}</p>
                      </div>
                      <div className="tp-team__social">
                        {team.social_links.map((l, i) => (
                          <a key={i} href={l.link} target={l.target} className="mx-2">
                            {l.icon === "fab fa-twitter" ? ( // Check if the icon is Twitter
                              <img
                                src="/assets/img/icons/x-twitter.svg"
                                alt="Twitter"
                                style={{ width: '15px', marginBottom: '2px' }}
                              />
                            ) : (
                              <i className={l.icon}></i> // Use font icon for other links
                            )}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
