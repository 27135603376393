import React from "react";
import Menu from "../../common/Menu/Menu";
import Footer from "../../common/Footer/Footer";
import CookieBanner from "./CookieBanner";
import CookieAbout from "./CookieAbout";

const Cookie = () => {
    return (
        <>
            <Menu />
            <CookieBanner />
            <CookieAbout />
            <Footer />
        </>
    );
};

export default Cookie;
