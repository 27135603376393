import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";

import IdareHukuku from "./IdareHukuku";

const IdareHukukuPage = () => {
  return (
    <>
      <Menu header_style_1={true} />
      <div className="service-placeholder mt-130">
      </div>
      <IdareHukuku />
      <Footer />
    </>
  );
};

export default IdareHukukuPage;
