import React from "react";

const CookieBanner = () => {
  return (
    <>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay about_banner"
        style={{
          backgroundImage: `url("assets/img/cookie/cookie.jpeg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title mt-100">ÇEREZLER</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookieBanner;
