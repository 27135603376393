import React from "react";
import Menu from "../../common/Menu/Menu";
import PriceBanner from "./PriceBanner";
import Services from "../../HomeTwo/Services";
import Footer from "../../common/Footer/Footer";

const Price = () => {
  return (
    <>
      <Menu />
      <PriceBanner />
      <Services />
      {/* <PriceDetails />
      <Testimonial />
      <Brands style_2={true} />
      <Contact />
      <FooterThree /> */}
      <Footer />
    </>
  );
};

export default Price;
