import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";

import IsVeSosyalGuvenlik from "./IsVeSosyalGuvenlik";

const IsveSosyalGuvenlikHukuku = () => {
  return (
    <>
      <Menu header_style_1={true} />
      <div className="service-placeholder mt-130">
      </div>
      <IsVeSosyalGuvenlik />
      <Footer />
    </>
  );
};

export default IsveSosyalGuvenlikHukuku;
