import React from 'react';
import { Link, useNavigate } from 'react-router-dom';


const Menus = () => {
  const navigate = useNavigate();

  const handleHakkimizdaClick = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById('hakkimizda');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <ul>
      <li>
        <span
          onClick={handleHakkimizdaClick}
          style={{ fontSize: "16px", color: "white", cursor: "pointer" }}
          className="mr-20"
        >
          HAKKIMIZDA & KURUCUMUZ
        </span>
      </li>
      <li>
        <Link to="/calisma-alanlarimiz">Çalışma Alanlarımız</Link>
        <ul className="submenu">
          <li>
            <Link to="/is-ve-sosyal-guvenlik-hukuku">- İş ve Sosyal Güvenlİk Hukuku</Link>
          </li>
          <li>
            <Link to="/dava-takibi">- Dava Takibi & Anlaşmazlıkların Çözümü</Link>
          </li>
          <li>
            <Link to="/aile-hukuku">- Aİle Hukuku</Link>
          </li>
          <li>
            <Link to="/gayrimenkul-hukuku">- Gayrİmenkul Hukuku</Link>
          </li>
          <li>
            <Link to="/sozlesmeler-hukuku">- Sözleşmeler Hukuku</Link>
          </li>
          <li>
            <Link to="/ticaret-ve-sirketler-hukuku">- Tİcaret ve Şİrketler Hukuku</Link>
          </li>
          <li>
            <Link to="/ceza-hukuku">- Ceza Hukuku</Link>
          </li>
          <li>
            <Link to="/goc-ve-yabancilar-hukuku">- Göç ve Yabancılar Hukuku</Link>
          </li>
          <li>
            <Link to="/tuketici-hukuku">- Tüketİcİ Hukuku</Link>
          </li>
          <li>
            <Link to="/miras-hukuku">- Mİras Hukuku</Link>
          </li>
          <li>
            <Link to="/idare-hukuku">- İdare Hukuku</Link>
          </li>
          <li>
            <Link to="/icra-iflas-hukuku">- İcra & İflas Hukuku</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/iletisim">İletİşİm</Link>
      </li>
    </ul>
  );
};

export default Menus;
