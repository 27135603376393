import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";

import GocVeYabancilarHukuku from "./GocVeYabancilarHukuku";

const GocVeYabancilarHukukuPage = () => {
  return (
    <>
      <Menu header_style_1={true} />
      <div className="service-placeholder mt-130">
      </div>
      <GocVeYabancilarHukuku />
      <Footer />
    </>
  );
};

export default GocVeYabancilarHukukuPage;
