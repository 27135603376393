import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";

import AileHukuku from "./AileHukuku";

const AileHukukuPage = () => {
  return (
    <>
      <Menu header_style_1={true} />
      <div className="service-placeholder mt-130">
      </div>
      <AileHukuku />
      <Footer />
    </>
  );
};

export default AileHukukuPage;
